<div class="flex items-center justify-between !gap-2">
    <p class="first-letter:uppercase">
        {{ label | translate }} <span *ngIf="numLabel">x {{ numLabel }}</span>
    </p>
    <p class="text-palette-gray-900">
        <ng-container *ngIf="isOriginalCurrency; else exchangePrice">
            {{ deduct ? '- ' : ''}}{{ price | historyCurrency: exchangeRate.exchange_rate_value : exchangeRate.currency_exchange_rate : currency?.currency }}
        </ng-container>
        <ng-template #exchangePrice>
            {{ deduct ? '- ' : ''}}<app-price-format [price]="price"></app-price-format>
        </ng-template>
    </p>
</div>
  