<div
    class="my-price-details-section w-full min-[1100px]:w-[384px] !p-4 bg-palette-base-white border border-palette-gray-200 rounded-lg">
    <p class="text-palette-gray-900 font-inter text-base font-semibold !mb-4">
        {{'MY_FLIGHT_BOOKING_DETAIL.BOOKER_INFO' | translate}}</p>
    <div>
        <div *ngIf="booker?.username !== 'anonymous'" class="flex flex-row items-center !gap-2">
            <div>
              <img [src]="getAvatarUrl(booker?.avatar_thumbnail_url)"
                   (error)="replaceWithDefaultImageUser($event)"
                   alt=""
                   class="rounded-full min-w-8 max-w-8"
              />
            </div>
            <div>
              <p class="font-inter text-sm !font-semibold text-palette-gray-900">{{ booker?.full_name }}</p>
              <div class="flex flex-row !gap-1 items-center">
                <p class="text-palette-gray-500 text-sm">{{ booker?.email }}</p>
              </div>
            </div>
          </div>
          <div *ngIf="booker?.username === 'anonymous'" class="flex flex-row items-center !gap-2">
            <div>
              <img src="assets/images/default_user_avatar.png"
                   alt=""
                   class="rounded-full min-w-8 max-w-8"
              />
            </div>
            <div>
              <p class="font-inter text-sm !font-semibold text-palette-gray-900">{{ 'BOOKING_HELPDESK.ANONYMOUS' | translate }}</p>
            </div>
          </div>
    </div>
</div>