import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DateFilter, DateFilterType } from 'src/app/core/enum/date-filter.enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { CalendarTypeView } from 'primeng/calendar';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
  @ViewChild('overlayPanelCalendar') overlayPanelCalendar: OverlayPanel;
  @Input() optionDefault: DateFilterType = DateFilterType.ALL_TIME;
  @Input() dateFormatReturn: string = 'YYYY-MM-DD';
  @Input() resetSignal: boolean = false;
  @Output() onApplyCalendar = new EventEmitter();
  @Input() startDate: any;
  @Input() endDate: any;

  optionFilter = DateFilter;
  selectedOption: DateFilterType | null;
  selectedOptionTemp: DateFilterType | null;
  calendarValue: any;
  calendarValueTemp: any;
  selectionMode: string;
  inputString: string;
  inputStartDate: string;
  inputEndDate: string;
  view: CalendarTypeView;
  lang: string;
  formatDate: string;
  today: Date = new Date();

  constructor(
    private configPrimeNG: PrimeNGConfig,
    private translateService: TranslateService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    this.initializeLocaleMoment();
    this.onChangeLang();
    this.initOptionDefault();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resetSignal']) {
      this.initOptionDefault();
    }
    if (changes['startDate'] || changes['endDate']) {
      this.initOptionDefault();
    }
  }

  initOptionDefault() {
    if (this.startDate && this.endDate) {
      this.calendarValue = [
        moment(this.startDate).toDate(),
        moment(this.endDate).toDate(),
      ];
    } else {
      this.onSelectedOption({ value: this.optionDefault });
    }

    this.getInputString();
    this.getInputRangeDate();
    this.selectedOptionTemp = this.selectedOption;
    this.calendarValueTemp = this.calendarValue;
    this.onApplyCalendar.emit(this.formatData());
  }

  initializeLocaleMoment() {
    this.lang = this.translateService.currentLang;
    const dow = this.lang === 'vi' ? 1 : 0;
    this.formatDate = this.lang === 'vi' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    moment.updateLocale(moment.locale(), {
      week: { dow: dow },
    });
  }

  getInputRangeDate() {
    switch (this.selectedOption) {
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
        const today = moment(this.calendarValue).format(this.formatDate);
        this.inputStartDate = today;
        this.inputEndDate = today;
        break;
      case DateFilterType.THIS_WEEK:
      case DateFilterType.LAST_WEEK:
      case DateFilterType.THIS_MONTH:
      case DateFilterType.LAST_MONTH:
      case DateFilterType.THIS_YEAR:
      case DateFilterType.LAST_YEAR:
        this.inputStartDate = moment(this.calendarValue[0]).format(
          this.formatDate
        );
        this.inputEndDate = moment(this.calendarValue[1]).format(
          this.formatDate
        );
        break;
      case DateFilterType.ALL_TIME:
      case null:
        this.inputStartDate =
          this.calendarValue && this.calendarValue[0]
            ? moment(this.calendarValue[0]).format(this.formatDate)
            : '';
        this.inputEndDate =
          this.calendarValue && this.calendarValue[1]
            ? moment(this.calendarValue[1]).format(this.formatDate)
            : '';
        break;
      default:
        break;
    }
  }

  getInputString() {
    switch (this.selectedOption) {
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
        this.inputString = moment(this.calendarValue).format(this.formatDate);
        break;
      case DateFilterType.THIS_WEEK:
      case DateFilterType.LAST_WEEK:
      case DateFilterType.THIS_MONTH:
      case DateFilterType.LAST_MONTH:
      case DateFilterType.THIS_YEAR:
      case DateFilterType.LAST_YEAR:
        this.inputString =
          moment(this.calendarValue[0]).format(this.formatDate) +
          ' - ' +
          moment(this.calendarValue[1]).format(this.formatDate);
        break;
      case DateFilterType.ALL_TIME:
      case null:
        if (!this.calendarValue)
          this.inputString = this.translateService.instant(
            'CALENDAR_FILTER.ALL_TIME'
          );
        else {
          this.inputString =
            moment(this.calendarValue[0]).format(this.formatDate) +
            ' - ' +
            (this.calendarValue[1]
              ? moment(this.calendarValue[1]).format(this.formatDate)
              : moment(this.today).format(this.formatDate));
        }
        break;
      default:
        break;
    }
  }

  onOpenCalendarPanel(event: Event) {
    this.overlayPanelCalendar.show(event);
    this.setTranslateCalendar();
  }

  onChangeLang() {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initializeLocaleMoment();
      this.getInputString();
      this.getInputRangeDate();
    });
  }

  onSelectedOption(option: { value: DateFilterType | null }) {
    this.selectedOption = option.value;
    const currentDate = moment().startOf('day');

    switch (option.value) {
      case DateFilterType.TODAY:
        this.view = 'date';
        this.selectionMode = 'range';
        this.calendarValue = currentDate.toDate();
        break;
      case DateFilterType.YESTERDAY:
        this.view = 'date';
        this.selectionMode = 'range';
        this.calendarValue = currentDate.subtract(1, 'days').toDate();
        break;
      case DateFilterType.THIS_WEEK:
        this.view = 'date';
        this.selectionMode = 'range';
        let startDate = moment().startOf('weeks').toDate();
        let endDate = moment().endOf('weeks').toDate();
        this.calendarValue = [startDate, endDate];
        break;
      case DateFilterType.LAST_WEEK:
        this.view = 'date';
        this.selectionMode = 'range';
        const startOfLastWeek = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .toDate();
        const endOfLastWeek = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .toDate();
        this.calendarValue = [startOfLastWeek, endOfLastWeek];
        break;
      case DateFilterType.THIS_MONTH:
        this.view = 'date';
        this.selectionMode = 'range';
        const startOfMonth = currentDate.startOf('month').toDate();
        const endOfMonth = currentDate.endOf('month').toDate();
        this.calendarValue = [startOfMonth, endOfMonth];
        break;
      case DateFilterType.LAST_MONTH:
        this.view = 'date';
        this.selectionMode = 'range';
        const startOfLastMonth = moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate();
        const endOfLastMonth = moment()
          .subtract(1, 'month')
          .endOf('month')
          .toDate();
        this.calendarValue = [startOfLastMonth, endOfLastMonth];
        break;
      case DateFilterType.THIS_YEAR:
        this.view = 'date';
        this.selectionMode = 'range';
        const startOfYear = currentDate.startOf('year').toDate();
        const endOfYear = currentDate.endOf('year').toDate();
        this.calendarValue = [startOfYear, endOfYear];
        break;
      case DateFilterType.LAST_YEAR:
        this.view = 'date';
        this.selectionMode = 'range';
        const startOfLastYear = moment()
          .subtract(1, 'year')
          .startOf('year')
          .toDate();
        const endOfLastYear = moment()
          .subtract(1, 'year')
          .endOf('year')
          .toDate();
        this.calendarValue = [startOfLastYear, endOfLastYear];
        break;
      case DateFilterType.ALL_TIME:
      case null:
        this.view = 'date';
        this.selectionMode = 'range';
        this.calendarValue = undefined;
        break;
      default:
        break;
    }
    this.getInputRangeDate();
  }

  onSelectedDate(event: any) {
    // if(
    //     !(this.selectedOption === DateFilterType.TODAY
    //     || this.selectedOption === DateFilterType.YESTERDAY
    // )) {
    //     this.selectedOption = DateFilterType.ALL_TIME;
    // }
    if (this.selectedOption) {
      this.selectedOption = null;
    }
    this.getInputRangeDate();
  }

  setTranslateCalendar() {
    this.configPrimeNG.setTranslation({
      dayNames: this.translateService.instant('PRIMENG.DAY_NAMES'),
      dayNamesShort: this.translateService.instant('PRIMENG.DAY_NAMES_SHORT'),
      dayNamesMin: this.translateService.instant('PRIMENG.DAY_NAMES_MIN'),
      monthNames: this.translateService.instant('PRIMENG.MONTH_NAMES'),
      monthNamesShort: this.translateService.instant(
        'PRIMENG.MONTH_NAMES_SHORT'
      ),
      today: this.translateService.instant('PRIMENG.TODAY'),
      weekHeader: this.translateService.instant('PRIMENG.WEEK_HEADER'),
    });
  }

  onCancel() {
    this.onSelectedOption({ value: this.selectedOptionTemp });
    this.selectedOption = this.selectedOptionTemp;
    this.calendarValue = this.calendarValueTemp;
    this.getInputString();
    this.getInputRangeDate();
    this.overlayPanelCalendar.hide();
  }

  onApply() {
    this.onApplyCalendar.emit(this.formatData());
    this.getInputString();
    this.getInputRangeDate();
    this.selectedOptionTemp = this.selectedOption;
    this.calendarValueTemp = this.calendarValue;
    this.overlayPanelCalendar.hide();
  }

  isStartDate(date: any): boolean {
    if (this.calendarValue?.length > 0) {
      return (
        this.calendarValue !== undefined &&
        this.isSameDate(date, this.calendarValue[0])
      );
    } else {
      return (
        this.calendarValue !== undefined &&
        this.isSameDate(date, this.calendarValue)
      );
    }
  }

  isEndDate(date: any): boolean {
    return (
      this.calendarValue &&
      this.calendarValue[1] !== undefined &&
      this.isSameDate(date, this.calendarValue[1])
    );
  }

  onOutsideClick() {
    this.onCancel();
  }

  isSameDate(date1: any, date2: Date): boolean {
    const inputDate1 = new Date(date1.year, date1.month, date1.day);
    return inputDate1?.toDateString() === date2?.toDateString();
  }

  isInRange(date: any): boolean {
    if (!this.calendarValue) return false;
    const inputDate = moment(
      `${date.year}-${date.month + 1}-${date.day}`,
      'YYYY-M-D'
    );
    const start = moment(this.calendarValue[0]).startOf('day');
    const end = moment(this.calendarValue[1]).endOf('day');
    return inputDate.isBetween(start, end, 'day', '()');
  }

  formatData() {
    let startDate;
    let endDate;
    switch (this.selectedOption) {
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
        startDate = moment(this.calendarValue);
        endDate = moment(this.calendarValue);
        break;
      case DateFilterType.THIS_WEEK:
      case DateFilterType.LAST_WEEK:
      case DateFilterType.THIS_MONTH:
      case DateFilterType.LAST_MONTH:
      case DateFilterType.THIS_YEAR:
      case DateFilterType.LAST_YEAR:
      case DateFilterType.ALL_TIME:
      case null:
        if (this.calendarValue) {
          startDate = moment(this.calendarValue[0]);
          if (!this.calendarValue[1]) this.calendarValue[1] = this.today;
          endDate = moment(this.calendarValue[1]);
        }
        break;
      default:
        break;
    }
    if (this.dateFormatReturn === 'date') {
      return {
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
      };
    } else {
      return {
        startDate: startDate?.format(this.dateFormatReturn),
        endDate: endDate?.format(this.dateFormatReturn),
      };
    }
  }
}
