import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightBookingIssueControllerService } from '@booking/angular-booking-admin-service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { BookingIssueService } from 'src/app/services/booking-issue/booking-issue.service';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';

export enum TICKET_STATUS {
    HOLD = 'Hold',
    EXPIRED = 'Expired',
    TICKETING = 'Ticketing',
    TICKETED = 'Ticketed'
}

@Component({
    selector: 'app-view-details-flight',
    templateUrl: './view-details-flight.component.html',
    styleUrls: ['./view-details-flight.component.scss']
})
export class ViewDetailsFlightComponent {
    @Input() type: string;
    @Input() flightDetail: any;
    @Input() id: any = '';
    @Output() closePopupEmit = new EventEmitter<boolean>();

    detailsData: any;
    querySubscription: Subscription;
    pinCode: string;
    isLoading: boolean = true;
    onDestroy$: Subject<void> = new Subject();
    isClickBack: boolean = false;
    isShowChangeRequestHistoryPopup: boolean = false;
    changeRequestHistory: any;
    currentIndexTag: number = 0;
    currentSegments: any;
    currentSegmentType: string;
    currentFareRule: any;
    fareRuleVi: string;
    resource: string;
    colorStatus: string = '';
    status: string = '';
    colorTicketStatus: string = '';
    statusTicket: string = '';
    TooltipPosition: typeof TooltipPosition = TooltipPosition;
    TooltipTheme: typeof TooltipTheme = TooltipTheme;
    isShowViewDetails: boolean = true;
    bookingInfo: any;
    flightInfo: any;

    // @Output() isShowPreviewModeChange = new EventEmitter<boolean>();
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private bookingIssueService: BookingIssueService,
        private translateService: TranslateService,
        private flightBookingIssueControllerService: FlightBookingIssueControllerService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['id'] && changes['id'].currentValue) {
            this.flightDetail = null;
            this.isLoading = true;
            this.getViewDetailsData();
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        if (!this.isClickBack) {
            this.bookingIssueService.setCalendarValue(null);
        }
    }

    loadColorStatus(status: string) {
        const bookingStatus = status.toUpperCase();
        switch (bookingStatus) {
            case 'CONFIRMED':
            case 'RESCHEDULED':
                return 'text-palette-emerald-600 bg-palette-emerald-50';
            case 'PROCESSING':
                return 'text-palette-amber-600 bg-palette-amber-50';
            case 'CANCELED':
            case 'PENDING REFUND':
            case 'REFUNDED':
                return 'text-palette-gray-700 bg-palette-gray-100';
            case "PAYMENT FAILED":
                return 'bg-palette-red-50 text-palette-red-600';
            case "PENDING PAYMENT":
                return 'text-palette-orange-600 bg-palette-orange-50';
            case "PENDING BOOKING":
                return 'text-palette-orange-600 bg-palette-orange-50'
            default:
                return 'text-palette-red-600 bg-palette-red-50';
        }
    }

    getStatus(status: string): string {
        switch (status) {
            case "Pending booking":
                return "COMMON.PENDING_BOOKING";
            case "Confirmed":
                return "COMMON.CONFIRMED";
            case "Rescheduled":
                return "COMMON.RESCHEDULED";
            case "Processing":
                return "COMMON.PROCESSING";
            case "Completed":
                return "COMMON.COMPLETED";
            case "Pending refund":
                return "COMMON.PENDING_REFUND";
            case "Refunded":
                return "COMMON.REFUNDED";
            case "Refund failed":
                return "COMMON.REFUND_FAILED";
            case "Canceled":
                return "COMMON.CANCELED";
            case "Payment failed":
                return "COMMON.PAYMENT_FAILED";
            case "Pending payment":
                return "COMMON.PENDING_PAYMENT";
            default:
                return status;
        }
    }

    loadColorTicketStatus(status: string) {
        const ticketStatus = status?.toUpperCase();
        switch (ticketStatus) {
            case 'HOLD':
                return 'text-palette-yellow-600 bg-palette-yellow-50';
            case 'EXPIRED':
                return 'bg-palette-red-50 text-palette-red-600';
            case 'TICKETING':
                return 'text-palette-blue-700 bg-palette-blue-50';
            case "TICKETED":
                return 'bg-palette-green-50 text-palette-green-600';
            default:
                return 'text-palette-red-600 bg-palette-red-50';
        }
    }

    getTicketStatus(status: string): string {
        switch (status) {
            case TICKET_STATUS.HOLD:
                return "COMMON.TICKET_STATUS.HOLD";
            case TICKET_STATUS.EXPIRED:
                return "COMMON.TICKET_STATUS.EXPIRED";
            case TICKET_STATUS.TICKETED:
                return "COMMON.TICKET_STATUS.TICKETED";
            case TICKET_STATUS.TICKETING:
                return "COMMON.TICKET_STATUS.TICKETING";
            default:
                return status;
        }
    }

    getViewDetailsData() {
        this.flightBookingIssueControllerService
            .viewChildReservation(this.id)
            .subscribe({
                next: (res: any) => {
                    if (res.success) {
                        let requestProcess;
                        if (this.type === 'RESCHEDULE') {
                            const history = res.data.reschedule_history;
                            requestProcess = history[history.length - 1]?.request_process;
                            this.colorTicketStatus = this.loadColorTicketStatus('Ticketed');
                            this.statusTicket = this.getTicketStatus('Ticketed');
                        }
                        this.detailsData = null;
                        if (requestProcess?.length) {
                            for (let i = requestProcess.length - 1; i >= 0; i--) {
                                if (requestProcess[i].action_text === 'RESCHEDULED_SUCCESSFULLY') {
                                    this.detailsData = requestProcess[i];
                                    break;
                                }
                            }
                        }
                        this.colorStatus = this.loadColorStatus(res.data.reservation_status);
                        this.status = this.getStatus(res.data.reservation_status);

                        const itinerary = this.detailsData.itineraries[this.currentIndexTag];
                        if (itinerary) {
                            this.currentSegments = itinerary.segments;
                            this.currentSegmentType = itinerary.segment_type;
                            this.bookingInfo = {
                                reference_id: itinerary.segment_type === 'departure' ? itinerary.pnr_code : null,
                                inbound_reference_id: itinerary.segment_type !== 'departure' ? itinerary.pnr_code : null
                            };
                            this.flightInfo = {
                                source: null,
                                inbound_source: null
                            };
                        }
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                    }
                },
                error: () => {
                    this.isLoading = false;
                }
            });
    }

    changeCurrentTag(index: number) {
        this.currentIndexTag = index;
        // Re init data when mode changes
        const itinerary = this.detailsData.itineraries[index];
        if (itinerary) {
            this.currentSegments = itinerary.segments;
            this.currentSegmentType = itinerary.segment_type;
            this.bookingInfo = {
                reference_id: itinerary.segment_type === 'departure' ? itinerary.pnr_code : null,
                inbound_reference_id: itinerary.segment_type !== 'departure' ? itinerary.pnr_code : null
            };
            this.flightInfo = {
                source: null,
                inbound_source: null
            }
        }
    }

    handleHidePopup() {
        this.isShowViewDetails = false;
        this.closePopupEmit.emit();
    }

    formatTime(timeString: string): string {
        const timeParts = timeString.split(':');
        const date = new Date();
        date.setHours(Number(timeParts[0]));
        date.setMinutes(Number(timeParts[1]));

        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
    }

    formatDuration(timeString: string, space: boolean): string {
        const timeParts = timeString.split(':');
        const hours = Number(timeParts[0]);
        const minutes = Number(timeParts[1]);

        let formattedDuration = '';

        if (hours > 0) {
            if (space) {
                formattedDuration += `${hours}h `;
            } else {
                formattedDuration += `${hours}h`;
            }
        }

        formattedDuration += `${minutes}m`;

        return formattedDuration;
    }

    formatBaggages(num: any, quality: number, type: any): string {
        if (
            quality === 0 &&
            (type === 'K' || type === 'kg' || type == 'kilograms' || type === 'P')
        ) {
            return '';
        }

        switch (type) {
            case 'N':
                return `${num} ${this.translateService.instant('COMMON.PKG')}`;
            case 'K':
            case 'kg':
            case 'kilograms':
                return `${num}x${quality} kg`;
            case 'P':
                return `${num}x${quality} lb`;
            case 'pc(s)':
                return `${num} ${this.translateService.instant('COMMON.PCS')}`;
            default:
                if (quality && quality > 0) return `${num}x${quality} ${type}`;
                return `${num} ${type}`;
        }
    }
}
