import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, SimpleChanges } from '@angular/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';

interface ExpandState {
  isExpand: boolean;
  isMouseOver: boolean;
}
@Component({
  selector: 'meal-selection',
  templateUrl: './meal-selection.component.html',
  styleUrls: ['./meal-selection.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition(':enter', [animate('300ms ease-out')]),
      transition(':leave', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms ease-in')]),
      transition(':leave', [animate('300ms ease-out')]),
    ]),
  ],
})
export class MealSelectionComponent {
  @Input() segments: any;
  @Input() passengerInfo: any;

  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;

  isInitiated: boolean = false;
  expandMeal: ExpandState = { isExpand: false, isMouseOver: false };
  dataConvert: any = [];
  departureMeals = [];
  mealsSummary: any = [];
  count: number = 0;

  ngOnInit() {
    this.initData();
    this.isInitiated = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['passengerInfo'] && changes['passengerInfo'].currentValue && this.isInitiated) {
      this.count = 0;
      this.initData();
  }
  }

  initData() {
    this.mealsSummary = this.getMealsSummary(this.passengerInfo);
    this.countMeal();
  }

  getMealsSummary(passengerInfo: any) {
    const mealsSummary: any = {};

    if (passengerInfo != null) {
      passengerInfo?.forEach((passenger: any) => {
        const { title, last_name, middle_and_first_name, extra_meal_list } = passenger;
        
        extra_meal_list?.forEach((mealsSegment: any) => {
          const key = `${mealsSegment?.origin_iata_code}_${mealsSegment?.destination_iata_code}`;

          if (!mealsSummary[key]) {
            mealsSummary[key] = {
              origin_iata_code: mealsSegment.origin_iata_code,
              destination_iata_code: mealsSegment.destination_iata_code,
              passengers: [],
            };
          }

          mealsSummary[key].passengers.push({
            title,
            last_name,
            middle_and_first_name,
            passenger_type: passenger.passenger_type,
            meals: mealsSegment.meals,
          });
        });
      });
    }
    return Object.values(mealsSummary);
  }

  countMeal() {
    this.mealsSummary.forEach((flight: any) => {
      flight.passengers.forEach((passenger: any) => {
        passenger.meals.forEach((meal: any) => {
          this.count += meal.quantity;
        });
      });
    });

  }

  isEmptyObject(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  handleClickExpandCollapse(state: ExpandState) {
    state.isExpand = !state.isExpand;
  }

  handleMouseOverLeave(state: ExpandState, isOver: boolean) {
    state.isMouseOver = isOver;
  }

  toFirstUppercase(string: any) {
    return string
      .toLowerCase()
      .replace(/\b\w/g, (char: any) => char.toUpperCase());
  }
}
