import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'booker-info',
  templateUrl: './booker-information.component.html',
  styleUrls: ['./booker-information.component.scss']
})
export class BookerInformationComponent {
  @Input() booker: any;

  pathImage = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  
  getAvatarUrl(avatarUrl?: string): string {
    return `${this.pathImage}/${avatarUrl}.webp`
  }

  replaceWithDefaultImageUser(event: any): void {
    event.target.src = 'assets/images/default_user_avatar.png';
  }
}
