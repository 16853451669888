<div class="flex items-center justify-center relative w-full">
    <div class="relative font-inter w-full">
        <input
            type="text"
            [id]="ID"
            [disabled]="disabled"
            class="time-picker-custom text-sm text-palette-gray-700 font-medium {{style}}"
            [ngClass]="{
                'bg-palette-gray-50': disabled
            }"
            [(ngModel)]="timePickerValue"
            [placeholder]="placeholder"
            (focus)="onFocus($event)"
            (blur)="onBlur()"
            (ngModelChange)="onInput($event)"
            autocomplete="off"/>
        <i class="sctr-icon-clock absolute top-3 right-4 z-20 text-xl leading-5 text-palette-gray-400"></i>
        
        <div *ngIf="isShowTimePicker" 
            class="custom-shadow absolute z-[30] border bg-white rounded min-w-[160px] flex flex-col justify-center"
            [ngClass]="{
                'top-[calc(100%+4px)]': !isShowAbove,
                'bottom-[calc(100%+4px)]': isShowAbove
            }"
            (mousedown)="preventClose($event)">
            <div class="flex justify-center h-[220px] !pt-2 !pb-1 border-b w-full">
                <!-- HOURS -->
                <div [id]="ID + '_container_hours'"
                    class="flex flex-col w-16 h-full overflow-y-auto custom-scrollbar !px-1 border-r"> 
                    <ng-container *ngFor="let hour of hours">
                        <button [id]="ID + '_hour_' + hour" (click)="selectTime('hour', hour, ID + '_hour_' + hour, ID + '_container_hours')"
                            class="!px-3 !py-1 rounded hover:!bg-palette-gray-200 transition-all text-sm text-palette-gray-700 font-medium"
                            [ngClass]="selectedHour === hour ? 'bg-palette-blue-100' : 'bg-white'">
                            {{ hour }}
                        </button>
                    </ng-container>
                </div>
                <!-- MINUTES -->
                <div [id]="ID + '_container_minutes'"
                    class="flex flex-col w-16 h-full overflow-y-auto custom-scrollbar !px-1 {{second ? 'border-r': ''}}">
                    <ng-container *ngFor="let minute of minutes">
                        <button [id]="ID + '_minute_' + minute" (click)="selectTime('minute', minute, ID + '_minute_' + minute, ID + '_container_minutes')"
                            class="!px-3 !py-1 rounded hover:!bg-palette-gray-200 transition-all text-sm text-palette-gray-700 font-medium"
                            [ngClass]="selectedMinute === minute ? 'bg-palette-blue-100' : 'bg-white'">
                            {{ minute }}
                        </button>
                    </ng-container>
                </div>
                <!-- SECONDS -->
                <ng-container *ngIf="second">
                    <div [id]="ID + '_container_seconds'"
                        class="flex flex-col w-16 h-full overflow-y-auto custom-scrollbar !px-1">
                        <ng-container *ngFor="let second of seconds">
                            <button [id]="ID + '_second_' + second" (click)="selectTime('second', second, ID + '_second_' + second, ID + '_container_seconds')"
                                class="!px-3 !py-1 rounded hover:!bg-palette-gray-200 transition-all text-sm text-palette-gray-700 font-medium"
                                [ngClass]="selectedSecond === second ? 'bg-palette-blue-100' : 'bg-white'">
                                {{ second }}
                            </button>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="!px-3 !py-2 flex {{isShowNow ? 'justify-between' : 'justify-end'}}">
                <button *ngIf="isShowNow" (click)="onClickNow()"
                    class="text-sm text-palette-blue-600 font-medium hover:text-palette-blue-500">
                    {{ 'COMMON.NOW' | translate }}
                </button>
                <button (click)="onClickOK()"
                    class="rounded flex justify-center items-center text-sm text-white font-medium bg-palette-blue-600 hover:bg-palette-blue-500 px-3 py-2">
                    {{ 'COMMON.APPLY' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>