import { Component, EventEmitter, Input, Output, Renderer2, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimePickerComponent),
            multi: true
        }
    ],
})
export class TimePickerComponent implements ControlValueAccessor {
    @Input() form: any;
    @Input() controlName: string;
    @Input() controlValue: string;
    @Input() modelValue: any;
    @Input() ID: string;
    @Input() placeholder: string = 'Select time';
    @Input() hour: number = 24;
    @Input() minute: number = 60;
    @Input() second: number;
    @Input() isShowNow: boolean = false;
    @Input() style: string;
    @Input() isShowAbove: boolean = false;
    @Input() disabled: boolean = false;
    @Output() dataEmit = new EventEmitter();

    private onChange: any = () => { };
    private onTouched: any = () => { };

    value: string | null = '';
    isShowTimePicker: boolean = false;
    timePickerValue: string = '';

    hours: string[] = [];
    minutes: string[] = [];
    seconds: string[] = [];
    selectedHour: string | null = null;
    tempSelectedHour: string | null = null;
    selectedMinute: string | null = null;
    tempSelectedMinute: string | null = null;
    selectedSecond: string | null = null;
    tempSelectedSecond: string | null = null;

    ngOnInit() {
        for (let i = 0; i < this.hour; i++) {
            this.hours.push(i.toString().padStart(2, '0'));
        }
        for (let i = 0; i < this.minute; i++) {
            this.minutes.push(i.toString().padStart(2, '0'));
        }
        if (this.second) {
            for (let i = 0; i < this.second; i++) {
                this.seconds.push(i.toString().padStart(2, '0'));
            }
        }

        this.tempSelectedHour = this.selectedHour;
        this.tempSelectedMinute = this.selectedMinute;
        this.tempSelectedSecond = this.selectedSecond;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['controlValue'] && !this.modelValue) {
            if (this.controlValue) {
                const [hour, minute, second] = this.controlValue.split(':').map((part: any) => part.trim());
                this.tempSelectedHour = this.selectedHour = hour;
                this.tempSelectedMinute = this.selectedMinute = minute;
                if (this.second && second) {
                    this.tempSelectedSecond = this.selectedSecond = second;
                    this.timePickerValue = `${hour}:${minute}:${second}`;
                } else {
                    this.timePickerValue = `${hour}:${minute}`;
                }
            } else {
                this.tempSelectedHour = this.selectedHour = '';
                this.tempSelectedMinute = this.selectedMinute = '';
                this.tempSelectedSecond = this.selectedSecond = '';
                this.timePickerValue = '';
            }
        }
        if (changes['modelValue'] && changes['modelValue'].currentValue) {
            const [hour, minute, second] = this.modelValue.split(':').map((part: any) => part.trim());
            this.tempSelectedHour = this.selectedHour = hour;
            this.tempSelectedMinute = this.selectedMinute = minute;
            if (this.second && second) {
                this.tempSelectedSecond = this.selectedSecond = second;
                this.timePickerValue = `${hour}:${minute}:${second}`;
            } else {
                this.timePickerValue = `${hour}:${minute}`;
            }
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setValue(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const value = inputElement?.value || null;
        this.value = value;
        this.onChange(value);
        this.onTouched();
    }

    onFocus(event: FocusEvent) {
        (event.target as HTMLInputElement).focus({ preventScroll: true });

        this.isShowTimePicker = true;
        if (this.selectedHour) {
            setTimeout(() => {
                this.scrollIntoView(this.ID + '_container_hours', this.ID + '_hour_' + this.selectedHour);
                this.scrollIntoView(this.ID + '_container_minutes', this.ID + '_minute_' + this.selectedMinute);
                this.scrollIntoView(this.ID + '_container_seconds', this.ID + '_second_' + this.selectedSecond);
            }, 100)
        }
    }

    onBlur() {
        this.isShowTimePicker = false;
        this.selectedHour = this.tempSelectedHour;
        this.selectedMinute = this.tempSelectedMinute;
        this.selectedSecond = this.tempSelectedSecond;
        if ((this.second && this.selectedHour && this.tempSelectedMinute && this.selectedSecond) ||
            (!this.second && this.selectedHour && this.tempSelectedMinute)) {
            this.timePickerValue = this.second ? `${this.selectedHour}:${this.selectedMinute}:${this.selectedSecond}` :
                `${this.selectedHour}:${this.selectedMinute}`

        } else {
            this.timePickerValue = '';
        }
        if (this.form) {
            this.form.controls[this.controlName].markAllAsTouched();
        }
    }

    onInput(value: any) {
        const [hour, minute, second] = value.split(':').map((part: any) => part.trim());

        const validateTime = (time: string, max: number): boolean => {
            const parsedTime = parseInt(time);
            return time.length === 2 && parsedTime > 0 && parsedTime < max;
        };

        if (this.second) {
            if (validateTime(hour, this.hour) && validateTime(minute, this.minute) && validateTime(second, this.second)) {
                this.selectedHour = hour;
                this.selectedMinute = minute;
                this.selectedSecond = second;
            }
        } else {
            if (validateTime(hour, this.hour) && validateTime(minute, this.minute)) {
                this.selectedHour = hour;
                this.selectedMinute = minute;
            }
        }

        if (this.selectedHour) {
            this.scrollIntoView(this.ID + '_container_hours', this.ID + '_hour_' + this.selectedHour);
        }
        if (this.selectedMinute) {
            this.scrollIntoView(this.ID + '_container_minutes', this.ID + '_minute_' + this.selectedMinute);
        }
        if (this.second && this.selectedSecond) {
            this.scrollIntoView(this.ID + '_container_seconds', this.ID + '_second_' + this.selectedSecond);
        }
    }


    preventClose(event: MouseEvent) {
        event.preventDefault();
    }


    selectTime(unit: 'hour' | 'minute' | 'second', value: string, id: string, idContainer: string) {
        if (unit === 'hour') {
            this.selectedHour = value;
            if (!this.selectedMinute) this.selectedMinute = '00';
            if (!this.selectedSecond) this.selectedSecond = '00';
        } else if (unit === 'minute') {
            this.selectedMinute = value;
            if (!this.selectedHour) this.selectedHour = '00';
            if (!this.selectedSecond) this.selectedSecond = '00';
        } else if (unit === 'second') {
            this.selectedSecond = value;
            if (!this.selectedHour) this.selectedHour = '00';
            if (!this.selectedMinute) this.selectedMinute = '00';
        }
        this.scrollIntoView(idContainer, id);
        this.timePickerValue = this.second ? `${this.selectedHour}:${this.selectedMinute}:${this.selectedSecond}` :
            `${this.selectedHour}:${this.selectedMinute}`;
        this.onChange(this.timePickerValue);
    }

    scrollIntoView(containerID: string, id: string) {
        const container = document.getElementById(containerID) as HTMLElement;
        const target = document.getElementById(id) as HTMLElement;
        if (container && target) {
            const containerRect = container.getBoundingClientRect();
            const targetRect = target.getBoundingClientRect();
            const offset = targetRect.top - containerRect.top + container.scrollTop;

            container.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        }
    }

    onClickNow() {
        const now = new Date();

        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        if (this.second) {
            this.timePickerValue = `${hours}:${minutes}:${seconds}`;
            this.selectedSecond = seconds;
            // this.selectedSecond = this.tempSelectedSecond = seconds;
        } else {
            this.timePickerValue = `${hours}:${minutes}`;
        }
        this.selectedHour = hours;
        this.selectedMinute = minutes;
        // this.selectedHour = this.tempSelectedHour = hours;
        // this.selectedMinute = this.tempSelectedMinute = minutes;
        // const input = document.getElementById(this.ID + '_time-picker') as HTMLInputElement;
        // if (input) {
        //     input.blur();
        // }
        this.onChange(this.timePickerValue);
    }

    onClickOK() {
        this.tempSelectedHour = this.selectedHour;
        this.tempSelectedMinute = this.selectedMinute;
        this.tempSelectedSecond = this.selectedSecond;
        this.isShowTimePicker = false;
        const input = document.getElementById(this.ID) as HTMLInputElement;
        if (input) {
            input.blur();
        }
        this.onChange(this.timePickerValue);
        if (this.modelValue) {
            this.dataEmit.emit(this.timePickerValue);
        } else {
            if (this.form) {
                this.form.patchValue({
                    [this.controlName]: this.timePickerValue
                })
            }
        }
    }

    preventParentScroll(event: WheelEvent) {
        const target = event.target as HTMLElement;

        if (
            (target.scrollTop === 0 && event.deltaY < 0) ||
            (target.scrollHeight - target.scrollTop <= target.clientHeight && event.deltaY > 0)
        ) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
