import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  DateFilterType,
  DashboardDateFilterSection1,
  DashboardDateFilterSection2,
} from 'src/app/core/enum/date-filter.enum';

@Component({
  selector: 'app-calendar-dashboard',
  templateUrl: './calendar-dashboard.component.html',
  styleUrls: ['./calendar-dashboard.component.scss'],
})
export class CalendarDashboardComponent implements OnInit {
  @Input() dateFilterType: DateFilterType = DateFilterType.LAST_SIX_MONTHS
  @Output() rangeDatesChange = new EventEmitter<any>();
  @ViewChild('calendarDashBoard') calendarPanel: OverlayPanel;
  @ViewChild('inputText') inputElement: ElementRef;
  
  DFActive?: DateFilterType;
  translatedCalendar: any;
  selectionMode: string = 'range';
  viewDate: any = 'date';
  currentDate = new Date();
  dateFormat: string = 'DD/MM/YYYY';
  rangeDates: (Date | undefined)[] | Date | undefined = [];
  currentLanguage: string = 'en';
  DF1 = DashboardDateFilterSection1;
  DF2 = DashboardDateFilterSection2;
  isSelectedAllTime: boolean = false;
  
  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    
  ) {}

  ngOnInit(): void {
    this.formatDate();
    this.currentLanguage = this.translateService.currentLang || 'en';
    this.filterDate({ value: this.dateFilterType });
    this.sendData();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
      this.formatDate();
    });
  }

  formatDate() {
    if(this.currentLanguage === 'en') this.dateFormat = 'MM/DD/YYYY';
    else this.dateFormat = 'DD/MM/YYYY';
  }

  onFocus() {
    //this.renderer.addClass(this.inputElement.nativeElement,'input-focus');
    this.inputElement.nativeElement.classList.add('input-focus')
  }

  outFocus() {
    this.inputElement.nativeElement.classList.remove('input-focus');
    this.sendData();
  }

  sendData() {
    let range: any = this.rangeDates;
    if(this.rangeDates || Array.isArray(this.rangeDates)) {
      switch (this.DFActive) {
        case DateFilterType.DAY:
        case DateFilterType.TODAY:
        case DateFilterType.YESTERDAY:
          this.rangeDatesChange.emit({
            type: this.DFActive,
            value: [
              moment(range).format('YYYY-MM-DD'),
              moment(range).format('YYYY-MM-DD')
            ]
          });
          break;
        case DateFilterType.MONTH:
          this.rangeDatesChange.emit({
            type: this.DFActive,
            value: [
              moment(range).startOf('month').format('YYYY-MM-DD'),
              moment(range).endOf('month').format('YYYY-MM-DD')
            ]
          });
          break;
        case DateFilterType.YEAR:
          this.rangeDatesChange.emit({
            type: this.DFActive,
            value: [
              moment(range).startOf('year').format('YYYY-MM-DD'),
              moment(range).endOf('year').format('YYYY-MM-DD')
            ]
          });
          break;
        case DateFilterType.LAST_THREE_MONTHS:
        case DateFilterType.LAST_SIX_MONTHS:
        case DateFilterType.WEEK:
          if(!range[1]) {
            this.DFActive = DateFilterType.LAST_SIX_MONTHS;
            this.filterDate({ value: DateFilterType.LAST_SIX_MONTHS });
            range = this.rangeDates;
          }
          this.rangeDatesChange.emit({
            type: this.DFActive,
            value: [
              moment(range[0]).format('YYYY-MM-DD'),
              moment(range[1]).format('YYYY-MM-DD')
            ]
          });
          break;
        default:
          break;
      }
    }
  }

  get dateString() {
    let date: any = this.rangeDates;
    if(this.DFActive === DateFilterType.ALL_TIME && !this.isSelectedAllTime) {
      return this.translateService.instant('CALENDAR_FILTER.ALL_TIME');
    }
    switch (this.DFActive) {
      case DateFilterType.DAY:
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
        return moment(date).format(this.dateFormat);
      case DateFilterType.MONTH:
        return moment(date).format('MM/YYYY');
      case DateFilterType.YEAR:
        return moment(date).format('YYYY');
      case DateFilterType.LAST_THREE_MONTHS:
      case DateFilterType.LAST_SIX_MONTHS:
      case DateFilterType.WEEK:
      case DateFilterType.ALL_TIME:
        return moment(date[0]).format(this.dateFormat) + ' - ' + (date[1] ? moment(date[1]).format(this.dateFormat) : '');
      default:
        break;
    }
    return '';
  }

  setTranslateCalendar() {
    this.translatedCalendar = {
      dayNames: this.translateService.instant('PRIMENG.DAY_NAMES'),
      dayNamesShort: this.translateService.instant('PRIMENG.DAY_NAMES_SHORT'),
      dayNamesMin: this.translateService.instant('PRIMENG.DAY_NAMES_MIN'),
      monthNames: this.translateService.instant('PRIMENG.MONTH_NAMES'),
      monthNamesShort: this.translateService.instant('PRIMENG.MONTH_NAMES_SHORT'),
      today: this.translateService.instant('PRIMENG.TODAY'),
      weekHeader: this.translateService.instant('PRIMENG.WEEK_HEADER')
    };    
    this.config.setTranslation(this.translatedCalendar);
  }

  filterDate(item: { value: DateFilterType }) {
    const now = moment();
    let startDate: moment.Moment;
    let endDate: moment.Moment;

    startDate = now;
    endDate = now;

    switch (item.value) {
      case DateFilterType.DAY:
      case DateFilterType.TODAY:
        this.viewDate = 'date';
        this.selectionMode = 'single';
        startDate = now.startOf('day');
        this.rangeDates = startDate.toDate();
        break;
      case DateFilterType.YESTERDAY:
        this.viewDate = 'date';
        this.selectionMode = 'single';
        startDate = moment().subtract(1, 'days');
        this.rangeDates = startDate.toDate();
        break;
      case DateFilterType.LAST_THREE_MONTHS:
        this.viewDate = 'date';
        this.selectionMode = 'range';
        startDate = moment().subtract(2, 'months').startOf('month');
        endDate = moment().endOf('month');
        this.rangeDates = [startDate.toDate(), endDate.toDate()];
        break;
      case DateFilterType.LAST_SIX_MONTHS:
        this.viewDate = 'date';
        this.selectionMode = 'range';
        startDate = moment().subtract(5, 'months').startOf('month');
        endDate = moment().endOf('month');
        this.rangeDates = [startDate.toDate(), endDate.toDate()];
        break;
      case DateFilterType.WEEK:
        this.viewDate = 'date';
        this.selectionMode = 'range';
        startDate = moment().startOf('isoWeek');
        endDate = moment().endOf('isoWeek');
        this.rangeDates = [startDate.toDate(), endDate.toDate()];
        break;
      case DateFilterType.MONTH:
        this.selectionMode = 'single';
        this.viewDate = 'month';
        startDate = moment().startOf('month');
        this.rangeDates = startDate.toDate();
        break;
      case DateFilterType.YEAR:
        this.viewDate = 'year';
        this.selectionMode = 'single';
        startDate = moment().startOf('year');
        this.rangeDates = startDate.toDate();
        break;
      // case DateFilterType.ALL_TIME:
      //   this.isSelectedAllTime = false;
      //   this.selectionMode = 'range';
      //   this.viewDate = 'date';
      //   startDate = now.startOf('day');
      //   endDate = now.endOf('day');
      //   this.rangeDates = [startDate.toDate(), endDate.toDate()];
      //   break;
      default:
        break;
    }
    this.DFActive = item.value;
  }


  onSelect(event: any): void {
    this.isSelectedAllTime = true;
    switch (this.DFActive) {
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
        this.DFActive = DateFilterType.DAY;
        this.rangeDates = event;
        break;
      case DateFilterType.LAST_THREE_MONTHS:
      case DateFilterType.LAST_SIX_MONTHS:
        this.selectionMode = 'single';
        this.DFActive = DateFilterType.DAY;
        this.rangeDates = event[0];
        break;
      case DateFilterType.WEEK:
        let startOfWeek = moment(event[0]).startOf('isoWeek').toDate();
        let endOfWeek = moment(event[0]).endOf('isoWeek').toDate();
        this.rangeDates = [startOfWeek, endOfWeek];
        break;
      case DateFilterType.MONTH:
        this.rangeDates = event;
        break;
      case DateFilterType.YEAR:
        this.rangeDates = event;
        break;
      default:
        break;

    }
  }
}
