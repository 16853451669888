import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination-by-url',
  templateUrl: './pagination-by-url.component.html',
  styleUrls: ['./pagination-by-url.component.scss'],
})
export class PaginationByUrlComponent {
  PAGE_SIZE_LIST = [
    { page_size: 5 },
    { page_size: 10 },
    { page_size: 15 },
    { page_size: 20 },
    { page_size: 25 },
    { page_size: 30 },
  ];

  @Input() total: number;
  @Input() page: string;
  @Input() pageSizeList: any[] = this.PAGE_SIZE_LIST;
  @Input() isChangeURL?: boolean;

  // @Output() setPageNum: EventEmitter<number> = new EventEmitter<number>();
  // @Output() setPageSize: EventEmitter<number> = new EventEmitter<number>();
  @Output() pingDoneInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() pingCallAPI: EventEmitter<any> = new EventEmitter<any>();

  pageNum: number;
  pageSize: number;
  public pages: number[] = [];
  overlayVisible = false;
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      const pageNum = params.get('page-num');
      const pageSize = params.get('page-size');

      if (pageNum && +pageNum > 0) {
        this.pageNum = +pageNum;
        // this.setPageNum.emit(+pageNum);
      } else {
        this.pageNum = 1;
        // this.setPageNum.emit(1);
      }

      if (pageSize && this.isValidPageSize(pageSize)) {
        this.pageSize = +pageSize;
        // this.setPageSize.emit(+pageSize);
      } else {
        this.pageSize = 10;
        // this.setPageSize.emit(10);
      }

      if (!pageNum || !pageSize) {
        this.setNewParams({ 'page-num': this.pageNum, 'page-size': this.pageSize });
        return
      }

      if (this.isLoading) {
        this.pingDoneInit.emit({ pageNum: this.pageNum, pageSize: this.pageSize });
        this.isLoading = false;
      } else {
        this.pingCallAPI.emit({ pageNum: this.pageNum, pageSize: this.pageSize });
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['total'] && changes['total'].currentValue) {
      this.pages = this.getPages(this.pageNum, this.total);
    }
  }

  setNewParams(params: any) {
    // Update the URL with the new page number
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge'
      });
  }

  isValidPageSize(pageSizeParam: string | null): boolean {
    if (!pageSizeParam) {
      return false;
    }
    return this.PAGE_SIZE_LIST.some(item => item.page_size.toString() === pageSizeParam);
  }



  public onGoTo(page: number): void {
    this.pageNum = page;
    this.setNewParams({ 'page-num': page });
    this.pages = this.getPages(this.pageNum, this.total);
    // this.setPageNum.emit(page);
  }

  public onNext(): void {
    if (this.pageNum < this.total) {
      this.pageNum += 1;
      this.setNewParams({ 'page-num': this.pageNum });
      this.pages = this.getPages(this.pageNum, this.total);
      // this.setPageNum.emit(this.pageNum);
    }
  }

  public onPrevious(): void {
    if (this.pageNum > 1) {
      this.pageNum -= 1;
      this.setNewParams({ 'page-num': this.pageNum });
      this.pages = this.getPages(this.pageNum, this.total);
      // this.setPageNum.emit(this.pageNum);
    }
  }

  handleChangePageSize(pageSize: number): void {
    this.overlayVisible = !this.overlayVisible;
    this.pageNum = 1;
    this.pageSize = pageSize;
    this.setNewParams({
      'page-num': 1,
      'page-size': pageSize
    });
    // this.setPageNum.emit(1);
    // this.setPageSize.emit(pageSize);
  }

  private getPages(current: number, total: number): number[] {
    if (total <= 7) {
      return [...Array(total).keys()].map((x) => ++x);
    }

    if (current >= 3 && current < total - 2) {
      if (current >= total - 4) {
        return [1, -1, total - 4, total - 3, total - 2, total - 1, total];
      }
      if (current >= 5) {
        return [1, -1, current - 1, current, current + 1, -1, total];
      }
      return [1, -1, 3, 4, 5, -1, total];
    }
    return [1, 2, 3, -1, total - 2, total - 1, total];
  }
}