import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-price-display',
    templateUrl: './price-display.component.html',
    styleUrls: ['./price-display.component.scss'],
})
export class PriceDisplayComponent {
    @Input() label!: string;
    @Input() numLabel: number = 0;
    @Input() price!: number;
    @Input() currency!: any;
    @Input() exchangeRate!: any;
    @Input() bookingStatus!: string;
    @Input() deduct: boolean = false;

    get isOriginalCurrency(): boolean {
        return (
            this.currency?.currency === this.exchangeRate?.currency_exchange_rate?.toUpperCase() &&
            this.bookingStatus?.toUpperCase() !== 'PENDING PAYMENT'
        );
    }
}
