<div *ngIf="flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'"
    class="w-full min-w-[300px] h-auto rounded-lg bg-palette-blue-50 border-1 border-palette-blue-600 !mb-2 !p-4">
    <p class="font-inter text-base font-semibold text-palette-gray-900">{{'FLIGHT.PAYMENT.CURRENCY_EXCHANGE' | translate}}</p>
    <div class="list-disc !ms-2">
        <li class="font-inter text-sm font-normal text-palette-gray-900">
            {{'FLIGHT.PAYMENT.PRIMARY_CURRENCY' | translate: { selectedCurrency: currency?.currency } }}
        </li>
        <li class="font-inter text-sm font-normal text-palette-gray-900">
            {{'FLIGHT.PAYMENT.CHOSEN_TO_PAY' | translate: { 
                currencyExchangeRate: flightDetail?.exchange_rate?.currency_exchange_rate.toUpperCase() } }}
        </li>
    </div>
    <p class="!mt-2 text-sm text-palette-gray-900">
        {{'FLIGHT.PAYMENT.RATE_ON_DATE' | translate }} 
        <span class="font-semibold">
            1$ = {{ flightDetail?.exchange_rate?.exchange_rate_value  }} {{ flightDetail?.exchange_rate?.currency_exchange_rate }}
        </span>
    </p>
</div>

<div class="my-price-details-section w-full min-[1100px]:w-[384px] bg-palette-base-white border border-palette-gray-200 rounded-lg">
    <p class="!pt-4 !px-4 text-palette-gray-900 font-inter text-lg font-semibold">
        {{'MY_FLIGHT_BOOKING_DETAIL.PRICE_DETAILS.TITLE' | translate}}
    </p>

    <!-- PRICE -->
    <ng-container *ngFor="let price of flightDetail?.price_details?.price_details; let i = index">
        <div class="!px-4 !pt-4 font-inter w-full">
            <div class="border-b border-b-palette-gray-200 !pb-4 flex flex-col !gap-3">
                <div (click)="toggleCollapse(i, 'summary')" class="flex items-center justify-between !gap-2 custom-bg !pl-2 py-[2px] cursor-pointer">
                    <h4 class="text-xs font-semibold text-palette-blue-600 uppercase">
                        {{ (i==0 ? 'FLIGHT.PAYMENT.DEPART' : 'FLIGHT.PAYMENT.RETURN') | translate }}
                    </h4>
                    <div class="flex items-center !gap-2">
                        <p class="text-sm font-medium text-palette-gray-900">
                            <span *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && 
                                flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; 
                                then originCurrency; else exchangeCurrency">
                            </span>
                            <ng-template #originCurrency>
                                {{ price.final_price | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                            </ng-template>
                            <ng-template #exchangeCurrency>
                                <app-price-format
                                    [price]="price.final_price">
                                </app-price-format>
                            </ng-template>
                        </p>
                        <span class="!w-4 !h-4 flex justify-center items-center">
                            <i class="text-base leading-4 {{collapsedStates[i].summary ? 'sctr-icon-chevron-right' : 'sctr-icon-chevron-down'}}"></i>
                        </span>
                    </div>
                </div>
                <!-- TICKET DETAILS -->
                <div *ngIf="!collapsedStates[i].summary" class="flex flex-col !gap-2 font-inter text-sm">
                    <div class="font-semibold text-palette-gray-900">
                        {{'COMMON.TICKET_DETAILS' | translate}}
                    </div>
                    <ng-container *ngFor="let item of ['adult', 'child', 'infant']">
                        <div *ngIf="(item === 'adult' && price.num_of_adults) || (item === 'child' && price.num_of_children) || 
                            (item === 'infant' && price.num_of_infants)"
                            class="flex flex-col !gap-2">
                            <!-- FINAL PRICE -->
                            <div (click)="toggleCollapse(i, item)" class="flex items-center justify-between !gap-2 cursor-pointer">
                                <div class="flex items-center !gap-2 text-palette-gray-900">
                                    <p class="first-letter:uppercase">
                                        <ng-container *ngIf="item === 'adult'">
                                            {{ 'COMMON.ADULT' | translate:{ syntax: '' } }} x {{ price.num_of_adults }}
                                        </ng-container>
                                        <ng-container *ngIf="item === 'child'">
                                            {{ 'COMMON.CHILD' |translate }} x {{ price.num_of_children }}
                                        </ng-container>
                                        <ng-container *ngIf="item === 'infant'">
                                            {{ 'COMMON.INFANT' | translate }} x {{ price.num_of_infants }}
                                        </ng-container>
                                    </p>
                                    <span class="!w-4 !h-4 flex justify-center items-center">
                                        <i class="text-base leading-4 {{collapsedStates[i][item] ? 'sctr-icon-chevron-right' : 'sctr-icon-chevron-down'}}"></i>
                                    </span>
                                </div>
                                <p class="font-medium text-palette-gray-900">
                                    <span *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && 
                                        flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; 
                                        then originFarePrice; else exchangeFarePrice">
                                    </span>
                                    <ng-template #originFarePrice>
                                        {{ price.origin_fare_base[item] + price.origin_tax[item] | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                                    </ng-template>
                                    <ng-template #exchangeFarePrice>
                                        <app-price-format [price]="price.origin_fare_base[item] + price.origin_tax[item]"></app-price-format>
                                    </ng-template>
                                </p>
                            </div>
                            <!-- DETAIL ADULT PRICE -->
                            <ng-container *ngIf="!collapsedStates[i][item]">
                                <!-- ORIGINAL PRICE -->
                                <div class="!pl-3 text-palette-gray-500">
                                    <app-price-display 
                                        [label]="'COMMON.ORIGINAL_PRICE'" 
                                        [price]="price.origin_fare_base[item]" 
                                        [currency]="currency" 
                                        [exchangeRate]="flightDetail.exchange_rate" 
                                        [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                    </app-price-display>
                                </div>
                                <!-- ORIGIN TAX -->
                                <div class="!pl-3 text-palette-gray-500">
                                    <app-price-display 
                                        [label]="'COMMON.ORIGIN_TAX'" 
                                        [price]="price.origin_tax[item]" 
                                        [currency]="currency" 
                                        [exchangeRate]="flightDetail.exchange_rate" 
                                        [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                    </app-price-display>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- TAXES, FEES AND CHARGES -->
                    <div class="flex flex-col !gap-2">
                        <div (click)="toggleCollapse(i, 'taxes_charges')" class="flex items-center justify-between !gap-2 cursor-pointer">
                            <div class="flex items-center !gap-2 text-palette-gray-900">
                                <p class="first-letter:uppercase">
                                    {{ 'COMMON.TAX_FEE_CHARGE' | translate}}
                                </p>
                                <span class="!w-4 !h-4 flex justify-center items-center">
                                    <i class="text-base leading-4 {{collapsedStates[i].taxes_charges ? 'sctr-icon-chevron-right' : 'sctr-icon-chevron-down'}}"></i>
                                </span>
                            </div>
                            <p class="font-medium text-palette-gray-900">
                                <span *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && 
                                    flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; 
                                    then originChargesCurrency; else exchangeChargesCurrency"></span>
                                <ng-template #originChargesCurrency>
                                    {{ (price?.tax?.['adult'] ?? 0) + (price?.tax?.['child'] ?? 0) + (price?.tax?.['infant'] ?? 0) +
                                    (price?.fee?.['adult'] ?? 0) + (price?.fee?.['child'] ?? 0) + (price?.fee?.['infant'] ?? 0) +
                                    (price?.charge?.['adult'] ?? 0) + (price?.charge?.['child'] ?? 0) + (price?.charge?.['infant'] ?? 0) | 
                                    historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                                </ng-template>
                                <ng-template #exchangeChargesCurrency>
                                    <app-price-format [price]="(price?.tax?.['adult'] ?? 0) + (price?.tax?.['child'] ?? 0) + (price?.tax?.['infant'] ?? 0) +
                                        (price?.fee?.['adult'] ?? 0) + (price?.fee?.['child'] ?? 0) + (price?.fee?.['infant'] ?? 0) +
                                        (price?.charge?.['adult'] ?? 0) + (price?.charge?.['child'] ?? 0) + (price?.charge?.['infant'] ?? 0)">
                                    </app-price-format>
                                </ng-template>
                            </p>
                        </div>
                        <ng-container *ngIf="!collapsedStates[i].taxes_charges">
                            <!-- TAXES -->
                            <div class="!pl-3 text-palette-gray-700">
                                <app-price-display 
                                    [label]="'COMMON.TAXES'" 
                                    [price]="(price?.tax?.['adult'] ?? 0) + (price?.tax?.['child'] ?? 0) + (price?.tax?.['infant'] ?? 0)" 
                                    [currency]="currency" 
                                    [exchangeRate]="flightDetail.exchange_rate" 
                                    [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                </app-price-display>
                            </div>
                            <ng-container *ngFor="let item of ['adult', 'child', 'infant']">
                                <ng-container *ngIf="(item === 'adult' && price.num_of_adults) || (item === 'child' && price.num_of_children) || 
                                    (item === 'infant' && price.num_of_infants)">
                                    <div class="!pl-6 text-palette-gray-500">
                                        <app-price-display 
                                            [label]="item === 'adult' ? 'FLIGHT.PAYMENT.ADULT' : 
                                                    item === 'child' ? 'COMMON.CHILD' : 
                                                    'COMMON.INFANT'" 
                                            [numLabel]="item === 'adult' ? price.num_of_adults : 
                                                item === 'child' ? price.num_of_children : 
                                                price.num_of_infants"
                                            [price]="price?.tax?.[item]" 
                                            [currency]="currency" 
                                            [exchangeRate]="flightDetail.exchange_rate" 
                                            [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                        </app-price-display>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- FEES -->
                            <div class="!pl-3 text-palette-gray-700">
                                <app-price-display 
                                    [label]="'COMMON.FEES'" 
                                    [price]="(price?.fee?.['adult'] ?? 0) + (price?.fee?.['child'] ?? 0) + (price?.fee?.['infant'] ?? 0)" 
                                    [currency]="currency" 
                                    [exchangeRate]="flightDetail.exchange_rate" 
                                    [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                </app-price-display>
                            </div>
                            <ng-container *ngFor="let item of ['adult', 'child', 'infant']">
                                <ng-container *ngIf="(item === 'adult' && price.num_of_adults) || (item === 'child' && price.num_of_children) || 
                                    (item === 'infant' && price.num_of_infants)">
                                    <div class="!pl-6 text-palette-gray-500">
                                        <app-price-display 
                                            [label]="item === 'adult' ? 'FLIGHT.PAYMENT.ADULT' : 
                                                    item === 'child' ? 'COMMON.CHILD' : 
                                                    'COMMON.INFANT'" 
                                            [numLabel]="item === 'adult' ? price.num_of_adults : 
                                                item === 'child' ? price.num_of_children : 
                                                price.num_of_infants"
                                            [price]="price?.fee?.[item]" 
                                            [currency]="currency" 
                                            [exchangeRate]="flightDetail.exchange_rate" 
                                            [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                        </app-price-display>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- SERVICE CHARGES -->
                            <div class="!pl-3 text-palette-gray-700">
                                <app-price-display 
                                    [label]="'COMMON.SERVICE_CHARGE'" 
                                    [price]="(price?.charge?.['adult'] ?? 0) + (price?.charge?.['child'] ?? 0) + (price?.charge?.['infant'] ?? 0)" 
                                    [currency]="currency" 
                                    [exchangeRate]="flightDetail.exchange_rate" 
                                    [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                </app-price-display>
                            </div>
                            <ng-container *ngFor="let item of ['adult', 'child', 'infant']">
                                <ng-container *ngIf="(item === 'adult' && price.num_of_adults) || (item === 'child' && price.num_of_children) || 
                                    (item === 'infant' && price.num_of_infants)">
                                    <div class="!pl-6 text-palette-gray-500">
                                        <app-price-display 
                                            [label]="item === 'adult' ? 'FLIGHT.PAYMENT.ADULT' : 
                                                item === 'child' ? 'COMMON.CHILD' : 
                                                'COMMON.INFANT'" 
                                            [numLabel]="item === 'adult' ? price.num_of_adults : 
                                                item === 'child' ? price.num_of_children : 
                                                price.num_of_infants"
                                            [price]="price?.charge?.[item]" 
                                            [currency]="currency" 
                                            [exchangeRate]="flightDetail.exchange_rate" 
                                            [bookingStatus]="flightDetail?.booking_info?.booking_status">
                                        </app-price-display>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <!-- ADJUSTMENT -->
                        <div class="!text-palette-gray-900">
                            <app-price-display 
                                [label]="'COMMON.ADJUSTMENT'" 
                                [price]="price?.adjustment_price" 
                                [currency]="currency" 
                                [exchangeRate]="flightDetail.exchange_rate" 
                                [bookingStatus]="flightDetail?.booking_info?.booking_status">
                            </app-price-display>
                        </div>
                        <!-- MEMBERSHIP -->
                        <div class="!text-palette-gray-900">
                            <app-price-display 
                                [label]="'COMMON.MEMBERSHIP'" 
                                [price]="price?.membership_discount" 
                                [currency]="currency" 
                                [exchangeRate]="flightDetail.exchange_rate" 
                                [bookingStatus]="flightDetail?.booking_info?.booking_status"
                                [deduct]="true">
                            </app-price-display>
                        </div>
                    </div>
                </div>
                <div *ngIf="!collapsedStates[i] && (serviceData[i].numSeat || serviceData[i].numMeal || serviceData[i]?.numExtraBaggage)"
                    class="!pt-2 flex flex-col !gap-2 border-t border-dashed border-palette-gray-200">
                    <p class="text-sm font-semibold text-palette-gray-900">
                        {{'FLIGHT.PAYMENT.SERVICES' | translate}}
                    </p>
                    <div *ngIf="serviceData[i].numExtraBaggage" class="flex justify-between">
                        <p class="text-sm text-palette-gray-700">{{'FLIGHT.PAYMENT.EXTRA_BAGGAGE_1' | translate}} x {{ serviceData[i].numExtraBaggage }}</p>
                        <p class="text-sm font-medium text-palette-amber-600">
                                <span
                                *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originBaggageCurrency; else exchangeBaggageCurrency"></span>
                            <ng-template #originBaggageCurrency>
                                {{ serviceData[i].totalExtraBaggage | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                            </ng-template>
                            <ng-template #exchangeBaggageCurrency>
                                <app-price-format class="text-sm font-medium text-palette-amber-600"
                                    [price]="serviceData[i].totalExtraBaggage"></app-price-format>
                            </ng-template>
                        </p>
                    </div>
                    <div *ngIf="serviceData[i].numSeat" class="flex justify-between">
                        <p class="text-sm text-palette-gray-700">{{'FLIGHT.PAYMENT.SEAT_SELECTION' | translate}} x {{ serviceData[i].numSeat }}</p>
                        <p class="text-sm font-medium text-palette-amber-600">
                            <span
                            *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originSeatCurrency; else exchangeSeatCurrency"></span>
                        <ng-template #originSeatCurrency >
                            {{ serviceData[i].totalSeat | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeSeatCurrency >
                            <app-price-format class="text-sm font-medium text-palette-amber-600"
                                [price]="serviceData[i].totalSeat"></app-price-format>
                        </ng-template>
                        </p>
                    </div>
                    <div *ngIf="serviceData[i].numMeal" class="font-inter">
                        <div class="flex flex-col !gap-1">
                            <div class="flex justify-between !gap-4">
                                <p class="text-sm font-normal text-palette-gray-700">{{'FLIGHT.PAYMENT.MEAL' | translate}} x {{serviceData[i].numMeal}}</p>
                                <span
                                    *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originMealCurrency; else exchangeMealCurrency"></span>
                                <ng-template #originMealCurrency>
                                    {{ serviceData[i].totalMeal | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                                </ng-template>
                                <ng-template #exchangeMealCurrency>
                                    <app-price-format
                                        class="text-sm font-medium text-palette-amber-600 whitespace-nowrap"
                                        [price]="serviceData[i].totalMeal"> </app-price-format>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Voucher -->
    <div *ngIf="flightDetail?.price_details?.vouchers?.length" class="!px-4 !pt-4">
        <p class="text-sm font-semibold text-palette-gray-900 capitalize !mb-2">
            {{'FLIGHT.PAYMENT.VOUCHER' | translate}}
        </p>
        <div class="flex flex-col !gap-1">
            <div *ngFor="let item of flightDetail?.price_details?.vouchers">
                <div class="flex justify-between items-center">
                    <div
                        class="flex items-center gap-1 bg-palette-emerald-50 !pr-2 !pl-[6px] !py-[2px] rounded-2xl">
                        <img class="w-[12px] h-[12px]" src="assets/images/flight-view-details/checked-voucher.svg"
                            alt="">
                        <span
                            class="text-system-success-700 font-inter text-xs leading-[18px] font-medium uppercase">{{item.voucher_code}}</span>
                    </div>
                    <div class="flex items-center">
                        <span class="mr-[4px] text-xs !leading-[18px] font-semibold text-palette-gray-900"> - </span>
                        <div class="font-inter text-sm font-semibold text-palette-gray-900">
                            <span
                            *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; then originDiscountCurrency; else exchangeDiscountCurrency"></span>
                        <ng-template #originDiscountCurrency>
                            {{ item.discount_price | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                        </ng-template>
                        <ng-template #exchangeDiscountCurrency>
                            <app-price-format [price]="item.discount_price"></app-price-format>
                        </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Total -->
    <div class="!p-4 flex flex-col !gap-1">
        <ng-container *ngIf="currency?.currency===this.flightDetail?.exchange_rate?.currency_exchange_rate?.toUpperCase() && 
            flightDetail?.booking_info?.booking_status.toUpperCase() !== 'PENDING PAYMENT'; else exchangeTotalCurrency">
            <div class="flex justify-between items-center">
                <h4 class="text-palette-gray-900 font-inter text-base font-semibold leading-6"> {{"COMMON.TOTAL" | translate}} </h4>
                <span class="text-palette-orange-dark-600 font-inter text-xl font-semibold leading-[30px]">
                    {{ flightDetail?.price_details.total_price | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                </span>
            </div>
            <div class="text-palette-gray-600 text-sm text-end">
                ({{ 'FLIGHT.PAYMENT.INCLUDED' | translate}} 
                <span class="text-palette-gray-900 font-semibold">
                    {{ flightDetail?.price_details.taxes_and_fees | historyCurrency: flightDetail?.exchange_rate?.exchange_rate_value : flightDetail?.exchange_rate?.currency_exchange_rate : currency?.currency }}
                </span> 
                {{ 'FLIGHT.PAYMENT.TAXES_AND_FEES' | translate}})
            </div>
        </ng-container>
        <ng-template #exchangeTotalCurrency>
            <div class="flex justify-between items-center">
                <h4 class="text-palette-gray-900 font-inter text-base font-semibold leading-6"> {{"COMMON.TOTAL" | translate}} </h4>
                <span class="text-palette-orange-dark-600 font-inter text-xl font-semibold leading-[30px]">
                    <app-price-format [price]="flightDetail?.price_details.total_price"></app-price-format>
                </span>
            </div>
            <div class="text-palette-gray-600 text-sm text-end">
                ({{ 'FLIGHT.PAYMENT.INCLUDED' | translate}} 
                <span class="text-palette-gray-900 font-semibold whitespace-nowrap">
                    <app-price-format [price]="flightDetail?.price_details?.taxes_and_fees"></app-price-format>
                </span> 
                {{ 'FLIGHT.PAYMENT.TAXES_AND_FEES' | translate}})
            </div>
        </ng-template>
    </div>

    <!-- Buttons -->
    <!-- <div class="!p-4 flex flex-col !gap-3 border-t-[1px] border-palette-gray-200">
        <button
            class="h-[44px] bg-palette-base-white hover:bg-palette-gray-50 border border-palette-gray-300 rounded-lg">
            <common-icon-text class="flex items-center gap-2 justify-center" [iconStyle]="'sctr-icon-file-x-02'"
                (click)="showCancelPopup()"
                [icon]="'text-xl font-medium text-palette-gray-900'"
                [text]="'MY_FLIGHT_BOOKING_DETAIL.CANCEL_BOOKING' | translate"
                [textStyle]="'font-inter text-palette-gray-900 text-sm font-semibold leading-5'">
            </common-icon-text>
        </button>
        <button class="h-[44px] bg-palette-base-white hover:bg-palette-gray-50 border border-palette-gray-300 rounded-lg">
            <common-icon-text 
                (click)="showReschedulePopup()"
                class="flex items-center gap-2 justify-center"
                [iconStyle]="'sctr-icon-calendar'"
                [icon]="'text-xl font-medium text-palette-gray-900'"
                [text]="'MY_FLIGHT_BOOKING_DETAIL.REQUEST_RESCHEDULE' | translate"
                [textStyle]="'font-inter text-palette-gray-900 text-sm font-semibold leading-5'">
            </common-icon-text>
        </button>
    </div> -->
</div>
<app-cancel-booking class="fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden !overflow-y-hidden !overflow-x-hidden"
    *ngIf="isShowCancelPopup"
    (closePopup)="closeCancelPopup()"></app-cancel-booking>
<app-reschedule-booking *ngIf="isShowReschedulePopup" (close)="closeReschedulePopup()"></app-reschedule-booking>

