import { Component, Input, OnInit } from '@angular/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { CurrencyService } from 'src/app/services/currency.service';

@Component({
    selector: 'app-price-details',
    templateUrl: './price-details.component.html',
    styleUrls: ['./price-details.component.scss'],
})
export class PriceDetailsComponent implements OnInit {
    TooltipPosition: typeof TooltipPosition = TooltipPosition;
    TooltipTheme: typeof TooltipTheme = TooltipTheme;
    isShowCancelPopup: boolean = false;
    isShowReschedulePopup: boolean = false;
    currency: any;
    priceMap: any = {
        adult: {
            fare_price: 'adult_fare_price',
            origin_price: 'origin_adult_fare_price',
            adjustment: 'adult_adjustment_price',
            membership: 'adult_membership_price',
        },
        child: {
            fare_price: 'child_fare_price',
            origin_price: 'origin_child_fare_price',
            adjustment: 'child_adjustment_price',
            membership: 'child_membership_price'
        },
        infant: {
            fare_price: 'infant_fare_price',
            origin_price: 'origin_infant_fare_price',
            adjustment: 'infant_adjustment_price',
            membership: 'infant_membership_price'
        }
    };
    serviceData = [
        {
            numSeat: 0,
            totalSeat: 0,
            numMeal: 0,
            totalMeal: 0,
            numExtraBaggage: 0,
            totalExtraBaggage: 0,
        },
        {
            numSeat: 0,
            totalSeat: 0,
            numMeal: 0,
            totalMeal: 0,
            numExtraBaggage: 0,
            totalExtraBaggage: 0,
        }
    ];
    collapsedStates: any[] = [];

    constructor(
        private currencyService: CurrencyService
    ) { }

    

    @Input() flightDetail: any;

    ngOnInit(): void {
        this.currency = JSON.parse(localStorage.getItem('currency-conversation-data') || '');

        this.currencyService.receiveSignalChangeCurrency().subscribe(() => {
            // this.currencyService.updateCurrency();
            this.currency = JSON.parse(localStorage.getItem('currency-conversation-data') || '');

        });
        this.flightDetail.passenger_information.forEach((passenger: any) => {
            // SEAT
            if (passenger?.departure_seat?.length) {
                this.serviceData[0].numSeat += passenger.departure_seat.length;
                passenger.departure_seat.forEach((seat: any) => {
                    this.serviceData[0].totalSeat += seat.price;
                });
                this.flightDetail?.price_details?.price_details
            }
            if (passenger?.return_seat?.length) {
                this.serviceData[1].numSeat += passenger.return_seat.length;
                passenger.return_seat.forEach((seat: any) => {
                    this.serviceData[1].totalSeat += seat.price;
                });
            }
            // EXTRA_BAGGAGE
            if (passenger?.departure_extra_baggage?.length) {
                this.serviceData[0].numExtraBaggage += passenger.departure_extra_baggage.length;
                passenger.departure_extra_baggage.forEach((baggage: any) => {
                    this.serviceData[0].totalExtraBaggage += baggage.price;
                });
            }
            if (passenger?.return_extra_baggage?.length) {
                this.serviceData[1].numExtraBaggage += passenger.return_extra_baggage.length;
                passenger.return_extra_baggage.forEach((baggage: any) => {
                    this.serviceData[1].totalExtraBaggage += baggage.price;
                });
            }
            // MEAL
            if (passenger?.departure_meals?.length) {
                passenger.departure_meals.forEach((segment: any) => {
                    if (segment.meals?.length) {
                        segment.meals.forEach((meal: any) => {
                            this.serviceData[0].numMeal += meal.quantity;
                            this.serviceData[0].totalMeal += meal.price;
                        });
                    }
                });
            }
            if (passenger?.return_meals?.length) {
                passenger.return_meals.forEach((segment: any) => {
                    if (segment.meals?.length) {
                        segment.meals.forEach((meal: any) => {
                            this.serviceData[1].numMeal += meal.quantity;
                            this.serviceData[1].totalMeal += meal.price;
                        });
                    }
                });
            }
        });

        if (this.flightDetail?.price_details?.price_details?.length) {
            this.collapsedStates = this.flightDetail.price_details.price_details.map((item: any) => {
                return {
                    summary: true,
                    adult: true,
                    child: true,
                    infant: true,
                    taxes_charges: true
                }
            })
        }
    }

    showCancelPopup() {
        this.isShowCancelPopup = true;
    }

    closeCancelPopup() {
        this.isShowCancelPopup = false;
    }

    showReschedulePopup() {
        this.isShowReschedulePopup = true;
    }

    closeReschedulePopup() {
        this.isShowReschedulePopup = false;
    }

    toggleCollapse(index: number, key: string) {
        this.collapsedStates[index][key] = !this.collapsedStates[index][key];
    }

    transformCurrency(value: number, showSymbol: boolean = true): any {
        let data = this.flightDetail.exchange_rate;
        if (showSymbol) {
            return (value * data.exchange_rate_value).toLocaleString('en-US', {
                style: 'currency',
                currency: data.currency_exchange_rate,
            });
        } else {
            return (value * data.exchange_rate_value).toLocaleString('en-US', {
                style: 'decimal',
                maximumFractionDigits: 2,
            });
        }
    }
}
