<p-dialog [(visible)]="isShowViewDetails" [draggable]="false" [modal]="true" [dismissableMask]="true" (onHide)="handleHidePopup()"
  [style]="{ width: '792px' }">
    <ng-template pTemplate="header">
        <div class="!px-4">
            <h1 class="text-lg text-gray-900 font-semibold">{{ 'COMMON.FLIGHT_DETAILS' | translate }}</h1>
            <span class="text-sm text-palette-gray-700">
                {{ 'BOOKING_ISSUE.FLIGHT_UPDATE' | translate: { day: detailsData?.updated_at | dateFormat: true: true } }}
            </span>
        </div>
    </ng-template>
    <div *ngIf="detailsData && !isLoading" class="flex flex-col !gap-4">
        <div class="border border-palette-gray-200 shadow-sm bg-white flex p-[7px] items-center rounded-lg text-sm font-semibold">
            <ng-container *ngFor="let item of detailsData.itineraries; let index = index">
                <button (click)="changeCurrentTag(index)"
                    class="min-w-[52px] rounded-lg h-9 flex justify-center items-center px-3 py-2 mr-2 cursor-pointer"
                    [ngClass]="currentIndexTag == index ? 'bg-palette-blue-50 text-palette-blue-600' : 'text-palette-gray-500 hover:bg-palette-gray-100'">
                    {{ item.segments[0].departure.iata_code }} -> {{ item.segments[item.segments.length-1].arrival.iata_code }}
                </button>
            </ng-container>
        </div>
        <!-- Booking info -->
        <div class="border-b border-dashed">
            <p class="text-palette-gray-900 text-base font-semibold">{{ 'BOOKING_RESERVATION.BOOKING_INFORMATION' | translate }}</p>
            <div class="!pt-4 !pb-5 grid grid-cols-3 !gap-3 m-0">
                <div class="col-span-1 flex flex-col !gap-1">
                    <div class="flex items-center !gap-1">
                        <p class="text-sm font-normal text-palette-gray-500">
                            {{ 'FLIGHT.PAYMENT.BOOKING_STATUS' | translate }}
                        </p>
                        <div class="relative hover:cursor-pointer">
                            <i
                                [position]="TooltipPosition.RIGHT"
                                [theme]="TooltipTheme.LIGHT"
                                [tooltip]="'FLIGHT.PAYMENT.BOOKING_STATUS_DESC' | translate"
                                class="sctr-icon-info-circle text-palette-gray-500 text-base"></i>
                        </div>
                    </div>
                    <span class="w-fit !px-2 !py-0.5 rounded-full text-xs whitespace-nowrap {{colorStatus}}">
                        {{ status | translate}}
                    </span>
                </div>
                <div class="col-span-1 flex flex-col !gap-1">
                    <div class="text-palette-gray-500 font-inter text-sm font-medium leading-5 flex items-center gap-1">
                        {{'MY_FLIGHT_BOOKING_DETAIL.BOOKING_INFORMATION.TICKET_STATUS' | translate}}
                        <div class="relative hover:cursor-pointer">
                            <i [theme]="TooltipTheme.LIGHT"
                                [tooltip]="'FLIGHT.PAYMENT.TICKET_STATUS_DESC' | translate" 
                                class="text-base sctr-icon-info-circle text-palette-gray-500 line-clamp-2"></i>
                        </div>
                    </div>
                    <span class="w-fit flex justify-center items-center !h-5 px-2 font-inter text-xs font-medium leading-4 rounded-full {{colorTicketStatus}}">
                        {{ statusTicket | translate }}
                    </span>
                </div>
                <div class="col-span-1 flex flex-col !gap-1">
                    <p class="text-sm font-normal text-palette-gray-500">{{ 'COMMON.ORDER_ID' | translate }}</p>
                    <p class="text-sm font-semibold text-palette-gray-900">{{ id }}</p>
                </div>
            </div>
        </div>
        <div class="!flex flex-col">
            <flight-schedule 
                [bookingInfo]="bookingInfo"
                [segments]="currentSegments"
                [flightInfo]="flightInfo"
                [isHidePNRCode]="false"
                [isHideGDSSource]="false"
                [isHideReferenceIdFailed]="false"
                [segment_type]="currentSegmentType">
            </flight-schedule >

            <!-- Passengers -->
            <app-passengers-info
                class="!mt-2"
                [passengerList]="detailsData.itineraries[currentIndexTag].passengers">
            </app-passengers-info>
        
            <!-- Extra Baggage -->
            <extra-baggage 
                [segments]="currentSegments"
                [passengerInfo]="detailsData.itineraries[currentIndexTag].passengers">
            </extra-baggage>
            
            <!-- Seat -->
            <seat-selection 
                [segments]="currentSegments"
                [passengerInfo]="detailsData.itineraries[currentIndexTag].passengers">
            </seat-selection>
            
            <!-- Meal -->
            <meal-selection 
                [segments]="currentSegments"
                [passengerInfo]="detailsData.itineraries[currentIndexTag].passengers">
            </meal-selection>
        </div>
    </div>
    <div *ngIf="!detailsData && isLoading">
        <div>
            <div class="flex space-x-0 lg:!space-x-6 w-full">
                <div class="w-full">
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="2rem"></p-skeleton>
                    </div>
                    <div
                        class="flex flex-col !gap-1 mb-2 border border-palette-gray-200 shadow-sm bg-white p-[7px] rounded-lg text-sm font-semibold">
                        <p-skeleton height="2rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                        <p-skeleton height="1.5rem"></p-skeleton>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="!pt-4">
            <button
                class="!h-11 !min-w-[88px] px-2 border border-palette-gray-300 
                    text-palette-gray-900 font-semibold rounded-lg hover:bg-palette-gray-200 
                    focus:outline-none focus:ring-2 focus:ring-palette-gray-300 focus:ring-opacity-50"
                (click)="handleHidePopup()">
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </div>
    </ng-template>
</p-dialog>